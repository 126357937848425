<template>
    <div v-if="ffInfo.id">
        <div class="user">
            <img v-if="ffInfo.user" :src="rImg(ffInfo.user.head_portrait)" class="tx" :onerror="txImg" alt="">
            <div class="name-box">
                <p class="name flex" v-if="ffInfo.user">{{ffInfo.user.name}}<span class="boy-tag girl-tag">{{ffInfo.user.age}}</span></p>
                <p class="des">{{ffInfo.index? '排名/':''}}点评人数：{{ffInfo.index ? ffInfo.index+'/':''}}{{ffInfo.scoring_count}}</p>
            </div>
            <div class="score" v-html="dealScore(ffInfo.score_avg)"></div>
        </div>
        <div class="score-box" v-if="user.sex!='女'">
            <div class="score" v-if="ffInfo.score!='未评分'">{{ffInfo.score}}</div>
            <div class="score" v-else>{{modal.score}}.0</div>
            <div class="flex-c">
                <div class="star" ref="star" @touchstart="touchmoveF($event);" @touchmove="touchmoveF($event);">
                    <div class="star-no"></div>
                    <div class="star-active" :style="'width:'+(modal.score*10)+'%'"></div>
                </div>
            </div>
            <el-button round class="app-btn" v-if="ffInfo.score == '未评分'" @click="submitM()">确定</el-button>
            <p class="tip" v-else>您已给出评分</p>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                id: '', // 大赛id
                uid: '', // 选手id
                ffInfo: {}, // 选手资料
                user: {}, // 我的用户资料
                modal: {
                    left: 0,
                    width: 0,
                    score: 10,
                },
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.id = this.$route.query.id;
            this.uid = this.$route.query.uuid;
            this.user = this.$y_getKey('userInfo') || {};
            this.getData();
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            ...mapMutations(["s_setUser", "s_setRegModal"]),
            // 获取选手情况
            async getData() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/v2.pageants/info", {
                    id: this.id,
                    user_id: this.uid
                });
                if (code == 200) {
                    this.ffInfo = data;
                }
            },
            // 分数处理
            dealScore(s) {
                if (!s) return s;
                var s2 = s.split('.');
                return `${s2[0]}.<span class="s-score">${s2[1]}</span>`
            },
            // 评分
            submitM() {
                var _this = this;
                let myid = this.$y_getKey('userid')
                if (!myid) {
                    this.s_setRegModal(1);
                    return;
                }
                var m = _this.ffInfo;
                if (!m) return;
                this.$y_confirm({
                    msg: '只能给她评分一次，确定给这个分数吗？'
                }, async (bi) => {
                    if (bi == 1) {
                        let ret = await _this.$y_ajax('api/v2.pageants/scoring', {
                            touser_id: m.user_id,
                            score: _this.modal.score
                        })
                        _this.$y_msg(ret.msg);
                        if (ret.code == 200) {
                            var total = m.score_avg * m.scoring_count + _this.modal.score;
                            m.scoring_count++;
                            _this.ffInfo.score_avg = (total / m.scoring_count).toFixed(1);
                            _this.ffInfo.score = _this.modal.score;
                            _this.modal.active = -1;
                        }
                    }
                })
            },
            touchmoveF(event) {
                var _this = this;
                if (_this.ffInfo.score != '未评分') return;
                var x = event.changedTouches[0].pageX;
                if (!_this.modal.left) {
                    _this.modal.left = _this.$refs.star.offsetLeft;
                    _this.modal.width = _this.$refs.star.offsetWidth;
                }
                var w = (x - _this.modal.left) / _this.modal.width;
                if (w < 0.2) {
                    w = 0.2;
                } else if (w > 1) {
                    w = 1;
                }
                var s = parseInt(w * 10);
                if (s % 2 != 0) {
                    // 接口分数限制为 2 - 4 - 6 - 8 - 10
                    s = s + 1;
                }
                _this.modal.score = s;
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser", ""]),
        },
        watch: {
            // 监听
            s_getUser() {
                // 登录/注册后 刷新数据
                this.user = this.$y_getKey('userInfo');
                this.getData();
            },
        }
    };
</script>

<style lang="less" scoped>
    .user {
        padding: 10px;
        display: flex;

        .tx {
            border-radius: 50%;
            margin-right: 10px;
        }

        .name-box {
            flex: 1;
        }

        .des {
            font-size: 12px;
            color: #aaa;
            margin-top: 5px;
        }


    }


    .score-box {
        background: #fafafa;
        text-align: center;
        margin: 15px;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .score-box .score {
        font-size: 20px;
        color: #fd5621;
    }

    .score-box .star {
        position: relative;
        margin-top: 15px;
    }

    .score-box .star-no {
        background-image: url('../../assets/heart.png');
        background-repeat: repeat-x;
        background-position: left center;
        background-size: 40px;
        width: 200px;
        height: 40px;
        filter: grayscale(1);
    }

    .score-box .star-active {
        background-image: url('../../assets/heart.png');
        background-repeat: repeat-x;
        background-position: left center;
        background-size: 40px;
        width: 100%;
        height: 40px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .tip {
        text-align: center;
        color: #aaa;
        font-size: 12px;
        margin-top: 15px;
    }

    .app-btn {
        background: #fd5621;
        border: 0;
        color: #fff;
        margin-top: 30px;
        width: 200px;
        padding: 18px;
        border-radius: 30px;

        &.ready {
            background: #aaa;
        }
    }
</style>